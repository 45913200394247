<!--非定制页面修改客制物料弹窗-->
<template>
  <el-dialog
    style="
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      height: 80%;
      width: 63%;
      --el-dialog-margin-top: 10vh;
    "
    :show-close="false"
    ref="refBtn"
    custom-class="no-header-dialog"
  >
    <div class="hostCustome">
      <div class="hostCustome-1">宁美主机定制</div>
      <div class="hostCustome-2">打造企业专属电脑，从宁美企业客制开始</div>
      <img
        src="./img/close.png"
        class="hostCustome-3"
        @click="confimCustomClose"
      />
    </div>
    <div class="hostCustome-4">
      <div class="hostCustome-4-1">
        <div class="hostCustome-4-1-plus">
          <div class="hostCustome-4-2">
            <img :src="customerFix" style="width: 440px; height: 440px" />
          </div>
        </div>
        <div class="hostCustome-5">
          <div
            :class="
              index == customerpitch
                ? 'hostCustome-5-1-plus'
                : 'hostCustome-5-1'
            "
            v-for="(item, index) in customerImgs"
            @click="pitchimg(index)"
          >
            <div style="width: 100%; text-align: center">
              <img
                :src="item.samplePictures[0].img"
                style="width: 110px; height: 110px"
              />
            </div>
            <div class="hostCustome-5-2">{{ item.samplePictureName }}</div>
          </div>
        </div>
      </div>
      <div class="hostCustome-6">
        <div class="hostCustome-6-1" v-if="customerType == 0">
          <div class="hostCustome-6-2">{{customerName}}</div>
          <div class="hostCustome-6-3-plus" v-if="customerLogo != ''">
            <img :src="customerLogo" />
          </div>
          <div class="hostCustome-6-3" v-else>
            <div class="hostCustome-6-3-1">
              <img src="./img/plus.png" style="width: 30px; height: 30px" />
            </div>
            <div class="hostCustome-6-3-2">上传图片</div>
            <div class="hostCustome-6-3-3">
              文件要求：300*300px,支持jpg/png,大小不超过5MB
            </div>
          </div>
          <div style="position: absolute;right: 106px;top: 426px;width: 200px;text-align: center;">{{originalFilename}}</div>
          <el-upload
            class="hostCustome-6-4-1"
            :action="uploadBrand.url"
            :headers="uploadBrand.headers"
            :on-success="uploadSuccessOne"
            :before-upload="beforeLogoUpload"
          >
            <el-button class="hostCustome-6-4">{{customerLogo != '' ? '重新上传' : '上传图片'}}</el-button>
          </el-upload>
        </div>
        <div class="hostCustome-6-1-else" v-else>
          <div class="hostCustome-else-1">{{customerImgsColorList.materialName}}</div>
          <div
            :class="
              color.checkState == 1
                ? 'hostCustome-else-active'
                : 'hostCustome-else-2'
            "
            v-for="(color, index) in customerImgsColor"
            @click="pitchcolor(index)"
          >
            {{ color.sampleName }}
          </div>
        </div>
        <div class="hostCustome-6-5" @click="confimCustom">
          确认定制 【¥{{ customerprice }}】
        </div>
        <div class="hostCustome-6-6" @click="confimCustomClose">取消</div>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, watchEffect, onUnmounted } from "vue";
import { customizedMaterialById, saveCustomizedMaterial } from "@/api/details";
import { SoftwareList, Software, listSoftware } from "@/api/Home/index.js";
import { UploadProps, UploadUserFile, ElMessage } from "element-plus";
import { getToken } from "@/utils/auth";
import emitter from "@/utils/eventBus";
//process.env.VUE_APP_BASE_API +
const uploadBrand = reactive({
  headers: { Authorization: "Bearer " + getToken() },
  url: "/goods/production/upload",
});
const productList = ref([]);
const logoInx = ref(0);
const customerLogo = ref("");
const uploadSuccessOne = (file) => {
  if (file.code == 200) {
    originalFilename.value = file.data.originalFilename;
    customerLogo.value = file.data.url;
    productList.value[logoInx.value].customerLogo = file.data.fileName;
  }
};
const customerImgs = ref([]);
const customerImgsColor = ref([]);
const customerImgsColorList = ref({});
const customerFix = ref("");
const originalFilename = ref("");
const customerName = ref("");
const customerpitch = ref(0);
const customerprice = ref(0);
const customerType = ref(0);
const pitchimg = (val) => {
  customerpitch.value = val;
  customerFix.value = customerImgs.value[val].samplePictures[0].img;
};
const pitchcolor = (val) => {
  customerImgsColor.value.forEach((element) => {
    element.checkState = 0;
  });
  customerFix.value = customerImgsColor.value[val].samplePictures[0].img;
  customerImgsColor.value[val].checkState = 1;
};
onUnmounted(() => {});
emitter.on("customId", (Fn) => {
  customerImgs.value = [];
  customerImgsColor.value = [];
  customerFix.value = "";
  if (Fn.configPrice && Fn.customId) {
    productList.value = Fn.productList;
    configPrice.value = Fn.configPrice;
    logoInx.value = Fn.proId;
    customizedMaterialById(Fn.customId).then((res) => {
      if (res.data.customizedMaterialSamplePOS) {
        customerImgs.value = res.data.customizedMaterialSamplePOS;
        customerFix.value =
          res.data.customizedMaterialSamplePOS[0].samplePictures[0].img;
        customerName.value =res.data.materialName;
        customerpitch.value = 0
          customerLogo.value =
          res.data.customizedMaterialSamplePOS[0].customerLogos ? res.data.customizedMaterialSamplePOS[0].customerLogos[0].img : '';
        if(res.data.customerLogos){
          customerLogo.value = res.data.customerLogos[0].img
        }
      } else {
        customerImgsColor.value = res.data.customizedMaterialSamplePOList;
        customerImgsColorList.value = res.data;
        res.data.customizedMaterialSamplePOList.forEach((item, index) => {
          if (item.checkState == 1) {
            customerFix.value = item.samplePictures[0].img;
          }
        });
      }
      customerprice.value = res.data.price;
      customerType.value = res.data.type;
    });
  }
});
const configPrice = ref();
const confimCustom = () => {
  if (customerType.value == 0) {
    //  productList.value[logoInx.value].checkState = 1
    if (productList.value[logoInx.value].customerLogo != "") {
      productList.value[logoInx.value].changeState = 1;
      productList.value[logoInx.value].originalFilename =
        originalFilename.value;
      let data = {
        configPrice: configPrice.value,
        productCustomizedMaterialPOList: productList.value,
      };
      saveCustomizedMaterial(data).then((res) => {
        if (res.code == 200) {
          emitter.emit("customClose", {
            customClose: true,
            productCustomizedMaterialPOS:
              res.data.productCustomizedMaterialPOList,
            configPrice: res.data.configPrice,
          });
        } else {
          ElMessage({
            message: res.message,
            type: "error",
          });
        }
      });
    } else {
      ElMessage({
        message: "请上传企业专属LOGO!",
        type: "error",
      });
      return
    }
  } else {
    customerImgsColorList.value.changeState = 1;
	  //customerImgsColorList.value.checkState = 0;
    customerImgsColorList.value.customizedMaterialSamplePOList =
      customerImgsColor.value;
    productList.value[logoInx.value] = customerImgsColorList.value;
    let data = {
      configPrice: configPrice.value,
      productCustomizedMaterialPOList: productList.value,
    };
    saveCustomizedMaterial(data).then((res) => {
      if (res.code == 200) {
        emitter.emit("customClose", {
          customClose: true,
          productCustomizedMaterialPOS:
            res.data.productCustomizedMaterialPOList,
          configPrice: res.data.configPrice,
        });
      } else {
        ElMessage({
          message: res.message,
          type: "error",
        });
      }
    });
  }
};
const confimCustomClose = () => {
  emitter.emit("customClose", {
		customClose: true,
	  productCustomizedMaterialPOS: productList.value,
	  configPrice: configPrice.value
	});
};
const beforeLogoUpload = (file) => {
  const isJPG = file.type.indexOf("image/") > -1;
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isJPG) {
    ElMessage({
      message: "文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件!",
      type: "error",
    });
  }
  if (!isLt2M) {
    ElMessage({
      message: "上传头像图片大小不能超过 10MB!",
      type: "error",
    });
  }
  return isJPG && isLt2M;
};
</script>
<style lang="less">
.hostCustome {
  width: 100%;
  height: 88px;
  background: #f2f2f2;
  position: relative;
  .hostCustome-1 {
    width: 108px;
    height: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 18px;
    color: #111111;
    position: absolute;
    top: 23px;
    left: 55px;
  }
  .hostCustome-2 {
    width: 252px;
    height: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    position: absolute;
    top: 51px;
    left: 54px;
  }
  .hostCustome-3 {
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    top: 36px;
    right: 48px;
  }
}
.hostCustome-4 {
  width: 100%;
  height: 100%;
  display: flex;
  .hostCustome-4-1 {
    width: 794px;
    height: 652px;
    background: #ffffff;
    .hostCustome-4-1-plus {
      height: 460px;
      width: 794px;
      .hostCustome-4-2 {
        margin: 20px auto 0 auto;
        width: 440px;
        height: 440px;
      }
    }
    .hostCustome-5 {
      height: 130px;
      width: 100%;
      display: flex;
      justify-content: center;
      .hostCustome-5-1 {
        width: 130px;
        height: 130px;
        margin-right: 10px;
        background: #ffffff;
        &:hover {
          cursor: pointer;
          background: #ffffff;
          border: 2px solid #ce1200;
        }
        &:active {
          background: #ffffff;
          border: 2px solid #ce1200;
        }
        .hostCustome-5-2 {
          width: 130px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          text-align: center;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .hostCustome-5-1-plus {
        width: 130px;
        height: 130px;
        margin-right: 10px;
        background: #ffffff;
        border: 2px solid #ce1200;
        .hostCustome-5-2 {
          width: 130px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          text-align: center;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  .hostCustome-6 {
    background: #f9f9f9;
    width: 100%;
    height: 652px;
    .hostCustome-6-1 {
      width: 280px;
      height: 382px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      margin: 48px 0 0 63px;
      .hostCustome-6-2 {
        text-align: center;
        width: 115px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin: 25px 0 15px 83px;
      }
      .hostCustome-6-3-plus {
        width: 220px;
        height: 220px;
        background: #fafafa;
        margin-left: 30px;
      }
      .hostCustome-6-3 {
        width: 220px;
        height: 220px;
        background: #fafafa;
        border: 1px dashed #cccccc;
        margin-left: 30px;
        .hostCustome-6-3-1 {
          margin: 78px 0 0 96px;
        }
        .hostCustome-6-3-2 {
          width: 53px;
          height: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          color: #666666;
          margin: 16px 0 0 84px;
        }
        .hostCustome-6-3-3 {
          width: 167px;
          height: 36px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          color: #999999;
          margin: 16px 0 0 26px;
        }
      }
      .hostCustome-6-4-1 {
        .el-upload-list__item {
          transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
          font-size: 0.875rem;
          color: var(--el-text-color-regular);
          margin-bottom: 0.3125rem;
          position: relative;
          box-sizing: border-box;
          border-radius: 0.25rem;
          width: 100%;
          display: none !important;
        }
      }
      .hostCustome-6-4 {
        width: 220px;
        height: 36px;
        line-height: 36px;
        background: #ffffff;
        border: 1px solid #ce1200;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 13px;
        margin: 38px 0 0 30px;
        color: #ce1200;
        cursor: pointer;
        &:hover {
          background: #ce1200;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          color: #ffffff;
        }
      }
    }
    .hostCustome-6-1-else {
      width: 280px;
      /* height: 382px; */
      background: #ffffff;
      border: 1px solid #e5e5e5;
      margin: 48px 0 0 63px;
      padding-bottom: 18px;
      .hostCustome-else-1 {
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin: 25px 0 25px 0;
      }
      .hostCustome-else-2 {
        width: 220px;
        height: 36px;
        line-height: 36px;
        background: #f2f2f2;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 13px;
        margin: 0 0 12px 30px;
        text-align: center;
        color: #333333;
        &:hover {
          background: #ffffff;
          border: 1px solid #ce1200;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          color: #ce1200;
          cursor: pointer;
        }
      }
      .hostCustome-else-active {
        width: 220px;
        height: 36px;
        line-height: 36px;
        background: #ffffff;
        border: 1px solid #ce1200;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 13px;
        margin: 0 0 12px 30px;
        text-align: center;
        color: #ce1200;
      }
    }
    .hostCustome-6-5 {
      width: 280px;
      height: 36px;
      line-height: 36px;
      background: #ce1200;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 13px;
      color: #ffffff;
      text-align: center;
      margin: 78px 0 0 63px;
      cursor: pointer;
    }
    .hostCustome-6-6 {
      width: 280px;
      height: 36px;
      line-height: 36px;
      background: #ffffff;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 13px;
      color: #333333;
      text-align: center;
      margin: 12px 0 0 63px;
      cursor: pointer;
      border: 1px solid #d9d9d9;
    }
  }
}
.no-header-dialog.el-dialog {
  .el-dialog__header {
    display: none;
  }
  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
